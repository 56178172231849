exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aesthetics-js": () => import("./../../../src/pages/aesthetics.js" /* webpackChunkName: "component---src-pages-aesthetics-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-courses-ggplot-2-fundamentals-js": () => import("./../../../src/pages/courses/ggplot2fundamentals.js" /* webpackChunkName: "component---src-pages-courses-ggplot-2-fundamentals-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-scales-js": () => import("./../../../src/pages/scales.js" /* webpackChunkName: "component---src-pages-scales-js" */),
  "component---src-pages-theme-js": () => import("./../../../src/pages/theme.js" /* webpackChunkName: "component---src-pages-theme-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-templates-scale-js": () => import("./../../../src/templates/scale.js" /* webpackChunkName: "component---src-templates-scale-js" */),
  "component---src-templates-tutorial-template-js": () => import("./../../../src/templates/tutorial-template.js" /* webpackChunkName: "component---src-templates-tutorial-template-js" */)
}

